import React from 'react';
import SEO from '../components/App/SEO';
import Layout from '../components/App/Layout';
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import { graphql } from "gatsby";

const MailboxPrivacyPolicy = ({ data }) => {
  let result = data.allMarkdownRemark.edges[0].node.html;
  let year = new Date();
  return (
    <Layout env={data.site.siteMetadata.env}>
      <Navbar />
      <SEO
        postTitle="Mailbox Privacy Policy | BlueMail App"
        postDescription="Learn about the privacy practices of the BlueMail Mailbox feature."
        postImage=""
        postURL="mailbox-privacy-policy"
        postSEO
      />
      <div className="template-page bg-nav">
        <div className="container">
          <div
            style={{ color: '#000' }}
            dangerouslySetInnerHTML={{ __html: result }}
          />
          <hr />
          <p>
            <a style={{ color: '#1AA3FF' }} href="https://blix.net">
              © {year.getFullYear()} Blix Inc.
            </a>
          </p>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default MailboxPrivacyPolicy;

export const pageQuery = graphql`
  query MailboxPrivacyPolicyQuery {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "mailbox-privacy" } } }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            templateKey
          }
        }
      }
    }
    site {
      siteMetadata {
        env
      }
    }
  }
`;
